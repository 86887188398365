@mixin navigation-filter-mark($bg-color, $color) {
    .dxbl-filter-content {
        background-color: #{$bg-color};
        color: #{$color};
    }
}

@mixin navigation-item-hover-state($bg-color-var, $color-var) {
    @include state-hover-style($bg-color-var, $color-var);
}

@mixin navigation-item-selected-state($bg-color-var, $color-var, $accordion-var: false) {
    @if $accordion-var {
        @include element-calculated-style($bg-color-var, $color-var, $dx-navigation-item-selected-bg, $dx-accordion-item-selected-base-bg, $dx-accordion-item-selected-bg-alpha);
    }

    @else {
        @include element-calculated-style($bg-color-var, $color-var, $dx-navigation-item-selected-bg, $dx-treeview-item-selected-base-bg, $dx-treeview-item-selected-bg-alpha);
    }
}

@mixin navigation-expand-button-style($color, $disabled-color, $hover-color, $btn-hover-bg, $btn-hover-color, $accordion-btn: false) {
    & > .dxbl-btn {
        position: relative;

        --dxbl-btn-box-shadow: none;
        --dxbl-btn-color: unset;
        --dxbl-btn-bg: transparent;
        --dxbl-btn-border-color: transparent;
        --dxbl-btn-border-hover-color: transparent;
        --dxbl-btn-hover-border-color: transparent;
        --dxbl-btn-hover-bg: transparent;
        --dxbl-btn-hover-background: transparent;
        --dxbl-btn-hover-color: $btn-hover-color;

        @include embedded-image-color($color, null, $hover-color);

        @if $accordion-btn == false {
            &:not(.dxbl-nav-expand-btn-custom) {
                --dxbl-btn-border-radius: 50%;

                @include hover-state() {
                    @include state-hover-style($btn-hover-bg, $btn-hover-color, "--dxbl-btn-border-width");
                }

                @include button-focus-state() {
                    @include button-inner-focus-style(var(--dxbl-btn-border-radius), -1px);
                }
            }
        }

        @content;
    }
}

@mixin treeview-checkbox-checked-style($color-var, $internal-color-var, $opacity) {
    &.dxbl-checkbox-checked,
    &.dxbl-checkbox-indeterminate {
        & > .dxbl-checkbox-check-element:not(:hover) {
            background-color: var(#{$color-var}, #{$internal-color-var});
            opacity: var(#{$color-var}, #{$opacity});
        }
    }
}
